import { openModalContent } from "../utils/modalManager"
import { TagIcon } from "./TagIcon"

export function TagIconAgGrid(row) {
    return {
        component: () => (
            // Msg do hint disponível por click para uso mobile
            <div style={{ paddingBottom: 5, }} title={row.hint} onClick={()=>openModalContent(row)}>
                <TagIcon name={row.icon} />
            </div>)
    }
}