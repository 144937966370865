import { TagIcon } from "./TagIcon";
import { openModalContent } from "../utils/modalManager";
// Componente AgGrid p/ exibição condicional de ícone ou texto
export function TextIconAgGrid(row) {
    return {
        component: () => (
            <div style={{ paddingBottom: 5, }} title={row.hint}
                onClick={() => openModalContent(row)}>
                {row.icon && <TagIcon name={row.icon} />}
                {row.txt &&
                    <div style={{ paddingLeft: 7, }} >
                        {row.txt}
                    </div>
                }
            </div>
        )
    }
}