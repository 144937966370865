
export function reloadItemsGroup(obj, groups, selectedModel) {
    if (!obj || !groups || !selectedModel) {
        return [];
    }

    const array = Object.values(obj).filter(item => {
        const groupId = item[selectedModel.group_id];
        return groups[groupId] !== undefined;
    }).map(item => {
        item.group = groups[item[selectedModel.group_id]];
        return item;
    });

    return array;
}

export function objToArray(obj) {
    const array = [];
    if (obj !== undefined) {
        for (const prop in obj) {
            if (obj[prop].group !== undefined) {
                array.push(obj[prop]);
            }
        }
    }
    return array;
}

export function setDeviceState(state, global) {
    if (state !== undefined) {
        global["deviceState"] = state;
    }
}

export function numberCellFormatter(params) {
    return (Math.round(params.value * 100) / 100).toFixed(2).toString();
}

export function offlineInfoFormatter(timeAt) {
    const diffMin = Math.floor((Math.abs((new Date()) - timeAt)) / (1000 * 60)).toString().padStart(3, ' ');
    return `UTAR Offiline há ${Math.round(diffMin / 60)} h e ${diffMin % 60} min\n`;
}

export function getOfflineTime(params) {
    if (params.node?.allLeafChildren
        && params.node.allLeafChildren[0].data?.group !== undefined
        && params.node.allLeafChildren[0].data.group?.deviceState !== undefined
        && params.node.allLeafChildren[0].data.group?.deviceState === "offline") {
        const dt = new Date();
        // Calcula a diferença em milissegundos entre as duas datas
        const diferenca = Math.abs(dt - params.node.allLeafChildren[0].data.group.deviceStateAt);
        // Calcula os dias, horas e minutos
        const horas = Math.floor(diferenca / (1000 * 60 * 60)).toString().padStart(3, ' ');
        const minutos = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, ' ');
        // Retorna o resultado formatado
        return `${horas} h e ${minutos} min`;
    } else {
        return null;
    }
}

export function getInfoMsg(isModal, group) {
    const tagInit = isModal ? '<b>' : '';
    const tagEnd = isModal ? '</b>' : '';
    const newLine = isModal ? '<br>' : '\n';
    const startup = (isModal ? group.deviceStartupInfoModal : group.deviceStartupInfoHint) || '';

    return `Utar: ${tagInit}${group.deviceId}${tagEnd}${newLine}` +
            `Versão: ${tagInit}${group.version}${tagEnd}${newLine}` +
            `State: ${tagInit}${group.deviceState}${tagEnd}${newLine}` +
            `Recebido: ${tagInit}${group.deviceStateAt.toLocaleString("pt-BR")}${tagEnd}${newLine}` +
            `Inicializações: ${tagInit}${group.startupCount}${tagEnd}${newLine}` +
            `Vezes offline: ${tagInit}${group.offlineCount}${tagEnd}${newLine}` +
            `${startup || ''}`;
}

export function getStartupInfoMsg(startupInfo) {
    const device = {
        "startupInfo":null, 
        "startupInfoModal":null , 
        "startupInfoHint":null 
    };
    if (startupInfo) {
        const { date, values } = JSON.parse(startupInfo);
        device.startupInfoModal = `Última inicialização: <b>${new Date(date).toLocaleString("pt-BR")}</b><br>`;
        device.startupInfoHint = `Última inicialização: ${new Date(date).toLocaleString("pt-BR")}\n`;
        for (let p in values) {
            device.startupInfoModal += `${p}: <b>${values[p]}</b><br>`;
            device.startupInfoHint += `${p}: ${values[p]}\n`;
        }
        device.startupInfo = startupInfo;
    }
    return device;
}