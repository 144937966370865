import { getGroupItems } from '../utils/dataCheck';
import { getInfoMsg } from '../utils/dataConverters';
import { TagIconAgGrid } from './TagIconAgGrid';
// Componente AgGrid p/ exibição de dados do Utar
export function DeviceInfoAgGrid(params) {    
    const row = {
        "group": getGroupItems(params),
        "icon": "info",
        "hint": "",
        "modal": ""
    };
    if (row.group && typeof row.group.deviceId !== "string") {
        console.error("DeviceInfoAgGrid",  "deviceId", row.group.deviceId);
        return null;
    }
    row.hint = getInfoMsg(false, row.group);
    row.modal = getInfoMsg(true, row.group);
    return TagIconAgGrid(row);
}
