import Badge from 'react-bootstrap/Badge';
import { TagIcon } from './TagIcon';
import { isObject, isNumber } from '../utils/dataCheck';
import { openModalContent } from "../utils/modalManager";

const styles =  {
    letterSpacing: 1,
    marginLeft: 5,
    marginTop: 5,
    paddingTop: 5
};
const stylesPrefix =  {
    letterSpacing: 1,
    marginRight: 5,
    marginTop: 5,
    paddingTop: 5
};
// Componente global de alerta
export function TagAlert({value, msg, target, max, tag, color, prefix, row}) {
    let hint = '';
    let modal = '<br>';
    const stBadge = {
        backgroundColor: !color ? "crimson" : color,
        paddingTop: 6,
        borderColor: 'white',
        borderWidth: 1,
        borderStyle: 'solid'
    };
    if (isObject(target)) {
        for (const key in target) {
            if (target[key]) {
                hint += key + ': ' + target[key].toFixed(2) + '\n';
                modal += key + ': <b>' + target[key].toFixed(2) + '</b><br>';
            }
        }
    } else {
        if (target && max) {
            hint += 'Max: ' + max.toFixed(2);
            hint += '\nTarget: ' + target.toFixed(2);
            modal += 'Max: <b>' + max.toFixed(2) + '</b>';
            modal += '<br>Target: <b>' + target.toFixed(2)+ '</b>';
        } else {
            if(msg){
                hint += msg;
                modal += msg;
            }else{
                hint += "";
                modal += "";
            }
        }
    }

    return (
        // Info do hint disponível por click para uso mobile
        <h6 onClick={()=>{
                if (row) {
                    openModalContent(row);
                } else {
                    alert(hint);
                }
            }} >
            <Badge variant="danger" style={stBadge} title={hint}>
                {prefix &&
                    <span style={stylesPrefix}  >
                        {prefix}
                    </span>
                }
                <TagIcon name={tag} />
                {value &&
                    <span style={styles}  >
                        {isNumber(value) ? value.toFixed(2) : value}
                    </span>
                }
            </Badge>
        </h6>
    );
}