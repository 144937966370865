import { gql } from '@apollo/client';

export const QUERY_MONITOR = gql`query getData($modulesSelect: [String!], $monitor: String!) {
    data(
        where: {
            identification: {
                monitor_id: {_eq: $monitor}
            },
            value_name: {_in: $modulesSelect}
        },
        order_by: { value_collected_at: desc }) {
        identification {
            name
            value
            order
        }
        description {
            value_label
            order
            visible
            value_reference_target
            value_reference_min
            value_reference_max
            value_unitMeasurement
        }
        alert_occurrence_at
        alert_out_of_bounds
        value_name
        value_value
        value_collected_at
        device_state
        device_state_at
        device_state_info
        device_id
        module_id
        startup_count
        offline_count
        device_startup_info
    }
}`;

export const SUB_MONITOR = gql`subscription getData($dateFilter: timestamptz!, $dateOfflineFilter: timestamptz!, $utarstate: String!, $modulesSelect: [String!], $monitor: String!) {
    data(
        where: {
                _or:[
                    {
                        value_name: {_in: $modulesSelect},
                        value_collected_at: {_gt: $dateFilter },
                        identification: {
                            monitor_id: {_eq: $monitor}
                        }, 
                    },
                    {
                        value_name: {_in: $modulesSelect},
                        device_state_at: {_gt: $dateOfflineFilter },
                        device_state: {_eq: $utarstate },
                        identification: {
                            monitor_id: {_eq: $monitor}
                        }, 
                    },
                    {
                        value_name: {_in: $modulesSelect},
                        device_state_at: {_gt: $dateFilter },
                        identification: {
                            monitor_id: {_eq: $monitor}
                        }, 
                    }
                ]
        },
        order_by: { value_collected_at: desc }) {
        identification {
            name
            value
            order
        }
        alert_occurrence_at
        alert_out_of_bounds
        value_name
        value_value
        value_collected_at
        device_state
        device_state_at
        device_state_info
        device_id
        module_id
        startup_count
        offline_count
        device_startup_info
    }
}`;

// export const SUB_UTAR = gql`subscription getData($dateFilter: timestamptz!, $ref: String!, $monitor: String!) {
//     data(
//         where: {
//             value_name: {_eq: $ref},
//             device_state_at: {_gt: $dateFilter },
//             identification: {
//                 monitor_id: {_eq: $monitor}
//             }, 
//         },
//         order_by: { device_state_at: desc }) {
//         identification {
//             name
//             value
//             order
//         }
//         description {
//           value_label
//           order
//           visible
//           value_reference_target
//           value_reference_min
//           value_reference_max
//           value_unitMeasurement
//         }
//         alert_occurrence_at
//         alert_out_of_bounds
//         value_name
//         value_value
//         value_collected_at
//         device_state
//         device_state_at
//         device_state_info
//         device_id
//         module_id
//         startup_count
//         offline_count
//     }
// }`;