import './ModalInfo.css';
import { closeModalContent } from "../utils/modalManager";
import { TagIcon } from "./TagIcon";

document.addEventListener('keydown', (e) => e.key === "Escape" && closeModalContent());

export function ModalInfo({icon}) {
    const iconRef = icon || "info";
    return (
        <div id="info_modal" class="modal"  onClick={(e)=>{ 
            if (e.type == "click" && e.target.id == "info_modal") {
                closeModalContent();
            }
        }}>
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-header-icon" >
                        <TagIcon name={iconRef} />
                    </div>
                    <div id="modal_header_title" class="modal-header-title">
                        
                    </div>
                    <span class="close" onClick={closeModalContent} >
                        <TagIcon name={"close"} />
                    </span>
                </div>
                <div class="modal-body" id="modal_body">

                </div>
            </div>
        </div>
    )
}