import { getStartupInfoMsg } from "./dataConverters";
import { compareDataIdentification } from "./sort";

export function warningExtractor(item, global, group, id) {
    if (global !== undefined  && group?.deviceStateInfo) {
        const stateInfo = JSON.parse(group.deviceStateInfo);
        group.warning = 0;
        for (const module_id in stateInfo) {
            if (module_id !== "version" && module_id === item.module_id) {
                if (stateInfo[module_id] !== "ok") {
                    global.warning = [{
                        module_id,
                        "name": item.value_name,
                        "warning_msg": stateInfo[module_id]
                    }];
                    group.warning = 1;
                    group.warningMap[id] = {
                        module_id,
                        "name": item.value_name,
                        "warning_msg": stateInfo[module_id]
                    };
                } else {
                    global.warning = [];
                    group.warningMap[id] = null;
                }
            }
        }
    }
}

// O Objeto grupo é responsável por armazenar dados comuns compartilhados entre linhas
//      de um mesmo grupo de dados, geralmente originados do mesmo Utar.
// Na versão inicial do monitor MML, todos os dados comuns eram atualizados linha-a-linha
//      pois todos os dados da telemetria eram sincronizados pelo hasura a cada atualização,
//      não sendo possível atualização parcial de dados.
// No novo modelo de sincronização com o hasura com filtro data/hora, com sincronização 
//      parcial dos dados, uma parte dos dados referente a um Utar ficavam defasados, 
//      sendo necessário um loop em todos os itens do AgGrid para atualização total.
// Com a criação do objeto grupo, é criado um lista desses, e após a primeira inicialização
//      dos dados, é referenciado em cada linha do AgGrid seu grupo correspondente.
// Nas atualização seguintes, os dados do grupo grupo são atualizados pela lista de grupos,
//      onde cada linha pode acessar os novos dados no AgGrid sem a necessídade de realizar 
//      um novo SET, já que a referencia inicial ao grupo se mantem durante todo o ciclo de 
//      vida da linha.
export function groupExtractor(globalGroups, Group, item, selectedModel, zone) {
    if (globalGroups[Group]) {
        globalGroups[Group].deviceState = item.device_state;
        globalGroups[Group].deviceStateAt = item?.device_state_at ? new Date(item?.device_state_at) : new Date();
        if (item?.offline_count) {
            globalGroups[Group].offlineCount = item.offline_count;
            globalGroups[Group].startupCount = item.startup_count;
        }
        if (item?.device_state_info) {
            globalGroups[Group].deviceStateInfo = item.device_state_info;
            globalGroups[Group].version = item.device_state_info ? JSON.parse(item.device_state_info)?.version : "-.-";
        }
        if (item?.device_startup_info !== globalGroups[Group].deviceStartupInfo) {
            const device = getStartupInfoMsg(item.device_startup_info);
            globalGroups[Group].deviceStartupInfoModal = device.startupInfoModal;
            globalGroups[Group].deviceStartupInfoHint =device.startupInfoModal;
            globalGroups[Group].deviceStartupInfo = item.device_startup_info;
        }
    } else {
        const device = getStartupInfoMsg(item.device_startup_info);
        globalGroups[Group] = {
            "deviceId": item.device_id,
            "deviceState": item.device_state,
            "deviceStateAt": item?.device_state_at ? new Date(item?.device_state_at) : new Date(),
            "offlineCount": item.offline_count,
            "startupCount": item.startup_count,
            "deviceStateInfo": item.device_state_info,
            "deviceStartupInfo": device.startupInfo,
            "deviceStartupInfoModal": device.startupInfoModal,
            "deviceStartupInfoHint": device.startupInfoHint,
            "valves": [],
            "zones": {},
            "warning": 0,
            "warningMap": {},
            "version": "-.-",
            "lamina": 0,
            "colors_palette": selectedModel.colors_palette
        };
        globalGroups[Group][selectedModel.group_id] = item.identification[0].value;
        for (const module of selectedModel.group_modules) {
            globalGroups[Group][module] = null;
            globalGroups[Group][module + 'At'] = null;
        }
    }

    if (item?.value_name && selectedModel.group_modules.includes(item.value_name)) {
        if (item.value_name === "controleValvula") {
            try {
                globalGroups[Group].valves[Number(item.module_id.slice(-1))] = item.value_value;
                if (zone) {
                    globalGroups[Group].zones[zone] = item.value_value;
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            globalGroups[Group][item.value_name] = item.value_value;
        }
        globalGroups[Group][item.value_name + 'At'] = new Date(item.value_collected_at).toLocaleString("pt-BR");
    }

    if (item?.value_name && selectedModel.references[item.value_name] && item?.description && item.description.length > 0) {
        if (globalGroups[Group]?.references === undefined) {
            globalGroups[Group]['references'] = {};
        }

        globalGroups[Group].references[item.value_name] = {
            "target": item.description[0].value_reference_target,
            "min": item.description[0].value_reference_min,
            "max": item.description[0].value_reference_max
        };
    }

}

// Implementa comportamento do monitor MML V1 se necessários
export function alertRowFormatter(global) {
    if (global !== undefined) {
        const { controleSistema, references, vazao } = global;
        if (controleSistema !== undefined && references) {
            if (controleSistema === 1 && references.vazao) {
                if (vazao > references.vazao.max) {
                    global.alerta = "bigger";
                } else if (vazao < references.vazao.min) {
                    global.alerta = "smaller";
                } else {
                    global.alerta = null;
                }
                global.diferenca = vazao - references.vazao.target;
            }
        } else if (controleSistema === 0 && vazao) {
            global.alerta = "out";
            global.diferenca = vazao;
        } else {
            global.alerta = null;
            global.diferenca = 0;
        }
    }
}
// Implementado clone do valor (real) dos dados de referencia após demanda para
//      suprimir valores de sensores com falha
// A supressão implica na ocultação do dado no AgGrid, assim como do calculo da
//      média do agrupamento, que não é possível sem a exclusão do campo original
// Quando ocorre a normalização do funcionamento do sensor, ocorre um delay no 
//      retorno da exibição do dado excluido, dependendo de um novo snapshot para
//      normalizar. 
// O clone do último valor possíbilita recuperar o mesmo logo após a normaização
// A informação de falha dos nodulos chega atravez do State do Utar
export function valueExtractor(item, global, selectedModel) {
    if (global !== undefined && !selectedModel.group_modules.includes(item.value_name)) {
        global[item.value_name] = item.value_value;
        global[item.value_name + 'At'] = new Date(item.value_collected_at).toLocaleString("pt-BR");
        if (selectedModel.references[item.value_name]) {
            global['real' + item.value_name] = item.value_value;
        }
        // No caso de warning no sensor, a telemetria enviada por ele é removida 
        //      para não ser incluída na média do AgGrid
        if (global?.moduleId && item.module_id === global.moduleId) {
            if (global.warning.length) {
                for (const refField in selectedModel.references) {
                    if (typeof global[refField] === "number") {
                        global[refField] = null;
                    }
                }
            }
        }
    }
}
// A prop moduleId é usada p/ identificação do sensor de origem dos dados de referência
export function referencesExtractor(item, global, selectedModel) {
    if (item?.value_name && item?.description
        && !selectedModel.group_modules.includes(item.value_name)
        && item.description.length > 0) {
        if (global.references === undefined) {
            global['moduleId'] = item.module_id;
            global.references = {};
        }
        global.references[item.value_name] = {
            "target": item.description[0].value_reference_target,
            "min": item.description[0].value_reference_min,
            "max": item.description[0].value_reference_max
        };
    }
}

const identToNotAdd = ["Zona"];

// isLine informa no fluxo se buscara uma linha com o ID para atualizar
//      ou é dado armazenado no Bloco (group)
export function idExtractor(item, selectedModel) {
    const id = [];
    const zones = [];
    let groupId = '';
    const linesId = [];
    const isLine = selectedModel.references[item.value_name] ? true : false;
    item.identification.sort(compareDataIdentification);
    for (const ident of item.identification) {
        if (ident?.value !== undefined && ident?.value !== null) {
            if (identToNotAdd.includes(ident.name)) {
                zones.push(ident.value);
            }
            id.push(ident.value);
            if (selectedModel.group_id === ident.name) {
                groupId = ident.value;
            }
        } else {
            return null;
        }
    }
    if (zones.length === 0) {
        zones.push("geral");
    }
    if (id.length) {
        const prefixId = id.join("#");
        for (const zone of zones) {
            linesId.push({
                "id": groupId + "#" + zone + "#" + prefixId,
                zone,
                isLine,
                "groupId": groupId || groupId + "#" + zone + "#" + prefixId
            });
        }
    }
    return { linesId };
}

// Insere os identificadores como parte dos dados da linha
// Necessário apenas no inicio
export function identificationExtractor(items, global) {
    if (global !== undefined) {
        const globalId = [];
        for (const item of items) {
            if (!identToNotAdd.includes(item.name)) {
                globalId.push(item.value);
                global[item.name] = item.value;
            }
        }
        if (!global['globalId']) {
            global['globalId'] = globalId.join("#");
        }
    }
}

export function dateExtractor(item, global, lastDate) {
    const value_collected_at = new Date(item.value_collected_at);
    if (global === undefined) {
        global = {
            "atualizado": value_collected_at,
            "atualizado_br": value_collected_at.toLocaleString("pt-BR")
        };
    } else {
        if (global?.atualizado !== undefined) {
            if (global.atualizado < value_collected_at) {
                global['atualizado'] = value_collected_at;
                global['atualizado_br'] = value_collected_at.toLocaleString("pt-BR");
            }
        } else {
            global['atualizado'] = value_collected_at;
            global['atualizado_br'] = value_collected_at.toLocaleString("pt-BR");
        }
    }

    if (lastDate) {
        if (!lastDate['value']) {
            lastDate['value'] = value_collected_at;
        } else {
            if (value_collected_at > lastDate['value']) {
                lastDate['value'] = value_collected_at;
            }
        }
    }
}