export function openModalContent(row) {
    if (row.modal) {
        const title = row?.group?.deviceId ? row.group.deviceId : "Informações";
        document.getElementById("modal_header_title").innerHTML = title;
        document.getElementById("modal_body").innerHTML = row.modal;
        document.getElementById("info_modal").style.display = "block";
    }
}

export function closeModalContent() {
    document.getElementById("modal_header_title").innerHTML = "";
    document.getElementById("modal_body").innerHTML = "";
    document.getElementById("info_modal").style.display = "none";
}